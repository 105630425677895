import { createStore } from 'vuex';

const store = createStore({

  state: {
    config: {
        resUrl: "https://braille.cz/test/client/",
        //php server:
        //serverUrl: "https://braille.cz/test/server/",
        serverUrl: "https://ickapp.cz/backend/server/",
        //serverUrl: "backend/server/",
      },
      session: "",
      account: {},
      rights: 0,
      sessiondata: "", //testdata, better object, data and sound
      sessionaudio: "",
      roomId: "",
      /*pro navigaci v URL*/
      selectedModule: {},
      selectedUser: {},
      selectedAccount: {},
      selectedTest: {},
      selectedTestSet: {},
      testResults1: {},
      testResults2: {},
      testCanvas1: {},
      testCanvas2: {},
      testReadTable1: "",
      testReadTable2: "",
      testPupils: {},
      /*ciselniky pro formulare*/
      lists: "",
      resultsListFilter: 1,
      maintenace_index: 0,
      maintenace_action: "",
      requiredAgreementVersion: 2,
      dirHandle: null,
      dirUserHandle: null,
      appRelease: "1.1.6",
      patientListFilter: "",
      selectedDocumentTab: 0,

    items: [], // Example state
    currentPage: 1,
    itemsPerPage: 20,
  },
  mutations: {
    mutate(state, payload) {
      state[payload.property] = payload.with;
    },
    setItems(state, items) {
      state.items = items;
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
  },
  actions: {
    fetchItems({ commit }) {
      // Simulate an API call
      return new Promise((resolve) => {
        setTimeout(() => {
          const items = [
            { id: 1, name: 'Item 1' },
            { id: 2, name: 'Item 2' },
            // More items...
          ];
          commit('setItems', items);
          resolve();
        }, 1000); // Simulated delay
      });
    },
  },
  getters: {
    paginatedItems(state) {
      const start = (state.currentPage - 1) * state.itemsPerPage;
      return state.items.slice(start, start + state.itemsPerPage);
    },
  },
});

export default store;
