<template>
  <div>
  
    <NavBar
      v-bind:items="[
        {
          text: 'Seznam',
          to: { name: 'Home' },
        },
        {
            text: userName,
            to: { name: 'UserDetail', params: {id: userId} } 
        },
        {
            text: docName, //'Dokument - ' + docName,
            to: { name: 'UserDetail' }
        },
      ]"
    />

   <PictureView  v-if="isImg" :imageUrl="docUrl" ref="embedImg"></PictureView>
 
   <iframe
      v-if="isPdf"
      v-bind:src="docUrl"
      width="100%"
      height="2000px"
      style="border: none;"
   ></iframe>

   <div v-if="isVideo">
    <video
      ref="videoPlayer"
      controls
      width="100%"
      @loadedmetadata="onLoadedMetadata"
      @error="onVideoError"
    >
      <source :src="docUrl" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
   </div>
     
  </div>
</template>
  
  <script>
  import PictureView from "../components/pictureview.vue";
  import NavBar from "../components/navbar.vue";

  export default {
    name: 'DocumentView',
    components: {
      PictureView,
      NavBar
  },
    data() {
    return {
      docName: '',
      docUrl: '', 
      isImg: false,
      isPdf: false,
      isDoc: false,
      isVideo: false,
      userId: 0,
      userName: "",
    };
  },

  methods: {
    onLoadedMetadata() {
      console.log("Video metadata loaded.");
    },
    onVideoError(event) {
      console.error("Error loading video:", event);
    }
    ,
    async fetchDoc() {
      try {
        //console.log(this.$route.params.path); 
        
        var docFile = this.$route.params.path;
        this.docName = this.$route.params.name;
        this.userId = this.$route.params.userid;
        this.userName = this.$route.params.username;
  
        const extension = docFile.split('.').pop().toLowerCase();
  
        switch (extension) {
          case 'jpg':
          case 'jpeg':
          case 'jfif':
            this.isImg = true;
            this.isPdf = false;
            this.isVideo = false;
            break;
          case 'pdf':
            this.isImg = false;
            this.isPdf = true;
            this.isVideo = false;
            break;
          case 'mp4':
            this.isImg = false;
            this.isPdf = false;
            this.isVideo = true;
            break;
          // Optionally handle DOC and DOCX if needed in the future
          // case 'doc':
          // case 'docx':
          //   // Handle DOC/DOCX if needed
          //   break;
          default:
            // No known file type matched
            this.isImg = false;
            this.isPdf = true;
            this.isVideo = false;
        }


        if (docFile.includes("local/")) {
          if (this.$store.state.userFolderHandle) {  
          const parts = docFile.split("/");
          const fullName = parts[1]; 

          // Iterate over the contents of the directory
          for await (const entry of this.$store.state.userFolderHandle.values()) {
              if (entry.kind === 'file') {
                  const file = await entry.getFile();
                  if (file.name == fullName)
                  {
                    if (file.type.match('video.*')) {
                      
                      // Revoke the old object URL if it exists
                      if (this.docUrl) {
                        URL.revokeObjectURL(this.docUrl);
                      }

                      // Create a new object URL
                      this.docUrl = URL.createObjectURL(file);
                      this.isVideo = true; 

                      this.$nextTick(() => {
                        // You can perform actions that depend on the DOM being rendered here.
                        this.$refs.videoPlayer.load(); // Force the video element to load the new source.
                      });
                    }
                    this.docUrl = URL.createObjectURL(file);
                    if (this.isImg) this.$refs.embedImg.loadData(this.docUrl);
                  }
              }
          }
         }
        }
        else
        {
          var serverPath = "https://ickapp.cz/backend/server";
          this.docUrl = serverPath  + "/ickdoc/" +  this.$route.params.userpid + "/" + this.$route.params.path;
        }
        
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    },

    callChildMethod() {
      // Checking if the child component is mounted and accessible
      if (this.$refs.embedImg) {
        var serverPath = "https://ickapp.cz/backend/server";
        this.$refs.embedImg.loadData(serverPath  + "/ickdoc/" + this.$route.params.userpid + "/" + this.$route.params.path);
      }
    }
  },
  created() {
    this.fetchDoc(); // Fetch user details when the component is created
  },
  mounted() {
    // Call the child method after the parent component has been mounted
    this.callChildMethod();
  },

  
};

  </script>