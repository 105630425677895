<template>
  <div
    class="zoom-pan-container"
    @mousedown="startPan"
    @mouseup="endPan"
    @mousemove="pan"
    @mouseleave="endPan"
    @wheel.prevent="zoom"
    @touchstart.prevent="startPan"
    @touchend="endPan"
    @touchmove.prevent="pan"
  >
  <canvas 
      ref="canvas" 
      :width="canvasWidth" 
      :height="canvasHeight" 
      class="responsive-canvas"
    ></canvas>

    <button class="zoom-button zoom-in" @click="zoomIn" @touchend="zoomIn">
     <i class="bi bi-plus"></i>
    </button>

  
    <button class="zoom-button zoom-out" @click="zoomOut" @touchend="zoomOut">
     <i class="bi bi-dash"></i>
    </button>

    <button class="zoom-button zoom-fit" @click="zoomToFit" @touchend="zoomToFit">
     <i class="bi bi-arrows"></i>
    </button>

  </div>
</template>

<script>
export default {
  name: "PictureView",
  data() {
      return {
          imageUrl: "",
          scale: 1,
          lastScale: 1,
          offsetX: 0,
          offsetY: 0,
          isPanning: false,
          startX: 0,
          startY: 0,
          canvasWidth: 1800,
          canvasHeight: 1600,
          image: new Image(),
          initialDistance: 0,
          zoomValue: "",
      };
  },
  mounted() {
      // this.image.src = this.imageUrl;
  },
  methods: {
      loadData(dataUrl) {
        console.log("loading...");  
        this.image.src = dataUrl;
          console.log(dataUrl);
          this.image.onload = () => {
            console.log("loaded...");
              this.canvasWidth = window.innerWidth;
              this.canvasHeight = (this.image.height / this.image.width) * this.canvasWidth;
              this.$nextTick(() => {
                this.zoomToFit();  
              });
          };
      },
      draw() {
          const ctx = this.$refs.canvas.getContext("2d");
          ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
          ctx.save();
          ctx.translate(this.offsetX, this.offsetY);
          ctx.scale(this.scale, this.scale);
          ctx.drawImage(this.image, 0, 0);
          ctx.restore();
      },
      zoom(event) {
          const wheelDelta = event.deltaY;
          this.lastScale = this.scale;

          if (wheelDelta > 0) {
              this.scale = Math.max(this.scale - 0.1, 0.1);
          } else {
              this.scale = Math.min(this.scale + 0.1, 5);
          }
          this.offsetX -= (event.offsetX - this.offsetX) * (this.scale - this.lastScale);
          this.offsetY -= (event.offsetY - this.offsetY) * (this.scale - this.lastScale);
          this.draw();
      },
      startPan(event) {
          this.isPanning = true;
          const clientX = event.touches ? event.touches[0].clientX : event.clientX;
          const clientY = event.touches ? event.touches[0].clientY : event.clientY;
          this.startX = clientX - this.offsetX;
          this.startY = clientY - this.offsetY;
      },
      endPan() {
          this.isPanning = false;
          this.initialDistance = 0; // Reset for touch events
      },
      panEx1(event) {
    if (this.isPanning) {
        const clientX = event.touches ? event.touches[0].clientX : event.clientX;
        const clientY = event.touches ? event.touches[0].clientY : event.clientY;

        // Calculate new offsets
        const newOffsetX = clientX - this.startX;
        const newOffsetY = clientY - this.startY;

        // Determine the maximum offset values
        const maxOffsetX = Math.min(0, this.canvasWidth * this.scale - this.image.width * this.scale);
        const minOffsetX = Math.max(0, this.canvasWidth - this.image.width * this.scale);
        const maxOffsetY = Math.min(0, this.canvasHeight * this.scale - this.image.height * this.scale);
        const minOffsetY = Math.max(0, this.canvasHeight - this.image.height * this.scale);

        // Apply limits to offsetX and offsetY
        this.offsetX = Math.max(maxOffsetX, Math.min(newOffsetX, minOffsetX));
        this.offsetY = Math.max(maxOffsetY, Math.min(newOffsetY, minOffsetY));

        this.draw();
    }
},
      pan(event) {
          if (this.isPanning) {
              const clientX = event.touches ? event.touches[0].clientX : event.clientX;
              const clientY = event.touches ? event.touches[0].clientY : event.clientY;
              this.offsetX = clientX - this.startX;
              this.offsetY = clientY - this.startY;
              this.draw();
          } 
          // else if (event.touches.length === 2) {
          //     // Handle pinch to zoom
          //     const { touches } = event;
          //     const currentDistance = this.calculateDistance(touches[0], touches[1]);

          //     if (this.initialDistance === 0) {
          //         this.initialDistance = currentDistance;
          //     }

          //     const scaleChange = currentDistance / this.initialDistance;
          //     this.scale = Math.min(Math.max(this.lastScale * scaleChange, 0.1), 5);

          //     this.offsetX -= (touches[0].clientX + touches[1].clientX) / 2 - this.offsetX * scaleChange;
          //     this.offsetY -= (touches[0].clientY + touches[1].clientY) / 2 - this.offsetY * scaleChange;

          //     this.draw();
          // }
      },
      calculateDistance(touch1, touch2) {
          const dx = touch2.clientX - touch1.clientX;
          const dy = touch2.clientY - touch1.clientY;
          return Math.sqrt(dx * dx + dy * dy);
      },
      // startTouch(event) {
      //     if (event.touches.length === 1) {
      //         this.startPan(event);
      //     }
      // }, 

      // New zoom in function
    zoomIn() {
        this.scale = Math.min(this.scale + 0.1, 5);
        this.zoomValue = this.scale;
        this.draw();
    },
    // New zoom out function
    zoomOut() {
        this.scale = Math.max(this.scale - 0.1, 0.1);
        this.zoomValue = this.scale;
        this.draw();
    },  
    zoomToFit() {
    const canvasAspectRatio = this.canvasWidth / this.canvasHeight;
    const imageAspectRatio = this.image.width / this.image.height;

    // Calculate the scale based on the aspect ratios
    if (canvasAspectRatio > imageAspectRatio) {
        this.scale = this.canvasHeight / this.image.height; // Fit by height
    } else {
        this.scale = this.canvasWidth / this.image.width; // Fit by width
    }

    // Center the image after fitting
    this.offsetX = (this.canvasWidth - this.image.width * this.scale) / 2;
    this.offsetY = (this.canvasHeight - this.image.height * this.scale) / 2;

    this.draw();
  },

  },
};
</script>

<style scoped>
.zoom-pan-container {
  border: 1px solid #ccc;
  overflow: hidden;
  cursor: grab;
  position: relative;
  width: 100%; /* Ensures the container can resize */
  background-color: #f0f0f0;
}

.responsive-canvas {
  width: 100%; /* Fill the width of the zoom-pan-container */
  height: auto; /* Maintain aspect ratio */
}

.zoom-pan-container:active {
  cursor: grabbing;
}


.zoom-button {
  position: fixed; /* Change to fixed positioning */
  right: 20px; /* Adjust the right positioning as needed */
  padding: 10px 15px; /* Add some padding for better touch targets */
  border: none;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
  color: #333; /* Dark text color */
  font-size: 20px; /* Increase font size */
  cursor: pointer; /* Pointer cursor on hover */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  transition: background-color 0.3s; /* Smooth background color change */
}

.zoom-button:hover {
  background-color: rgba(255, 255, 255, 1); /* Solid background on hover */
}

.zoom-in {
  top: 100px; /* Positioning for the zoom-in button */
}

.zoom-out {
  top: 160px; /* Positioning for the zoom-out button */
}

.zoom-fit {
  top: 220px; /* Positioning for the zoom-out button */
}
</style>