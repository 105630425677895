<template>
    <div>
      <NavBar
        v-bind:items="[
        //   {
        //     text: this.fullEdit ? 'Nastavení' : 'Seznam',
        //     to: { name: this.fullEdit ? 'AccountDetail' : 'AccountDetail' }
        //   },
        {
            text: 'Seznam',
            to: { name: 'Home' }
        },  
        {
            text: this.fullEdit ? 'Nastavení' : 'Profil',
            to: { name: 'AccountDetail' }
          }
        ]"
      />
      <div class="container p-5">
        ¨
        <div>
          <b-overlay :show="loadingUserForm" rounded="sm">
          
        <b-tabs content-class="mt-3">
        <b-tab title="Účet" active>
       
             <b-form class="col-6">
            <!--  <b-form-group
                id="input-group-id"
                label="ID:"
                label-for="input-id"
                label-cols="4"
              >
                <b-form-input id="input-id" v-model="accountDetail.id" disabled />
              </b-form-group> -->

              <b-form-group
                id="input-group-name"
                label="Jméno:"
                label-for="input-name"
                label-cols="4"
              >
                <b-form-input
                  id="input-name"
                  v-model="accountDetail.name"
                  required
                  placeholder="Zadejte jméno"
                ></b-form-input>
              </b-form-group>
  
              <b-form-group
                id="input-group-email"
                label="Email:"
                label-for="input-email"
                label-cols="4"
              >
                <b-form-input
                  id="input-email"
                  v-model="accountDetail.email"
                  required
                  placeholder="Zadejte email"
                />
              </b-form-group>
  
              <b-form-group
                id="input-group-role"
                label="Role:"
                label-for="input-role"
                label-cols="4"
              >
                <b-form-select
                  id="input-role"
                  v-model="accountDetail.role"
                  :options="this.level == 1 ? roles : roles2"
                  :disabled="this.fullEdit == false"
                ></b-form-select>
              </b-form-group>
             
              <b-form-group
                id="input-group-expiration"
                label="Omezení platnosti"
                label-for="input-expiration"
                label-cols="4"
              >
                <b-form-input
                  :disabled="this.fullEdit == false || this.scopeEdit == false"
                  id="input-expiration"
                  v-model="accountDetail.expiration"
                  type="date"
                  autocomplete="off"
                  locale="en"
                ></b-form-input>
              </b-form-group>
  
              <b-form-group
                id="input-group-pass"
                label="Password:"
                label-for="input-pass"
                label-cols="4"
              >
                <b-form-input
                  id="inputpass"
                  type="password"
                  v-model="passvalue"
                ></b-form-input>
              </b-form-group>
  
              <b-button
                type="submit"
                variant="outline-primary"
                @click="saveAccount"
                >Uložit</b-button
              >
              <!-- &nbsp;
              <b-button
                variant="danger"
                @click="deleteAccount"
                v-if="this.fullEdit == true"
                >Smazat</b-button
              > -->
              &nbsp;
              <b-button variant="danger" @click="changePassword"
                >Upravit heslo</b-button
              >
            </b-form>

        </b-tab>

        <b-tab title="Nastavení aplikace">
          <div class="button-container d-flex justify-content-start mt-3">
          
          <b-button
              variant="success"
              @click="bulkImport"
              class="float-left m-2"
              >Import dat csv</b-button
            >

          
          <!-- <div v-if="!$store.state.dirHandle"> -->
          
            <b-button
              v-if="accountDetail.role === 'admin'"
              variant="success"
              @click="loadLocalFolders"
              class="float-left m-2"
              >Vybrat složku data</b-button
            >

            <div v-if="showProgress">
              <progress :value="processedRecordsCount" :max="totalRecords"></progress>
                <p>{{ processedRecordsCount }} out of {{ totalRecords }} records processed.</p>
            </div>
          
            <!-- </div>  -->
          </div> 
        </b-tab>
      </b-tabs>

      </b-overlay>
      </div>
      </div>
      <input type="file" ref="fileInput" style="display: none;" @change="handleFileUpload"/>
     
    </div>
  </template>
  
  <script>
  import NavBar from "../components/navbar.vue";
  import { addIcUser } from '../services/api'; // Adjust import based on your file structure
  
  export default {
    name: "AccountDetail",
    components: { NavBar },
    methods: {
      saveAccount() {
        //console.log(this.accountDetail);
        this.loadingUserForm = true;
        this.axios
          .post("api", {
            method: "saveAccount",
            data: this.accountDetail
          })
          .then(response => {
            //console.log(response.data);
            this.accountDetail = response.data.data;
            this.$store.commit("mutate", {
              property: "selectedAccount",
              with: this.accountDetail
            });
            this.loadingUserForm = false;
            this.$router.go(-1);
          });
      },
  
      deleteAccount() {
        if (window.confirm("Vybraný účet bude vymazán. Pokračovat?")) {
          this.axios
            .post("api", {
              method: "deleteAccount",
              data: this.accountDetail
            })
            .then(response => {
              console.log(response.data);
              this.$router.go(-1);
            });
        }
      },
  
      changePassword() {
        if (this.passvalue.length < 3) {
          window.alert("Prázdné nebo krátké heslo.");
        } else {
          this.axios
            .post("api", {
              method: "savePassword",
              id: this.accountDetail.id,
              data: this.passvalue
            })
            .then(() => {
              //console.log(response.data);
              window.alert("Heslo bylo upraveno.");
            });
        }
      },

      bulkImport() {
      // Trigger the file input click
      this.$refs.fileInput.click();
    },

    handleFileUpload(event) {
     
    const file = event.target.files[0]; // Get the selected file
    //if (file && file.type === "text/plain") { 
    if (file) { 
      const reader = new FileReader();
      
      reader.onload = async (e) => {
        const fileContent = e.target.result; // File content in plain text
        console.log('File content:', fileContent);
        // get records and save to DB
          // Split content by line to get each record
          const records = fileContent.split('\n').map(line => line.trim()).filter(line => line); // Remove empty lines

          // Process each record
          const processedRecords = records.map(record => {
              const fields = record.split(';').map(field => field.trim()); // Split fields by semicolon and trim whitespace
              
              // Check for basic validity (e.g., number of fields, required fields)
              if (fields.length < 10) { 
                  console.error('Invalid record:', record);
                  return null; // Skip invalid record
              }

              // Map to user object
              return {
                user: {
                  surname: fields[0],
                  name: fields[1],
                  title: fields[2],
                  pid: fields[3],
                  hi: fields[4],
                  address: fields[5],
                  city: fields[6],
                  postcode: fields[7],
                  phone: fields[8],
                  email: fields[9],
                }
              };
          }).filter(record => record !== null); // Remove invalid records

          // console.log('Processed records:');
           // Iterate through each processed record and save it
          this.totalRecords = processedRecords.length;
          this.showProgress = true;
          var i = 1;
          for (const record of processedRecords) { 
          //processedRecords.forEach(record => {
            
            if (record) {
              console.log(i);
              console.log(record.user);
              this.processedRecordsCount = i;
              await new Promise(resolve => setTimeout(resolve, 500));
              addIcUser(record.user); 
              ++i;
               
            }
          }
          this.showProgress = false;
          alert("Importováno " + i + " záznamů.");
      };

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };

      reader.readAsText(file); // Read the file as text
      
    } else {
      alert("Vyberte platný soubor.");
    }
  },
  async loadLocalFolders() {
          try {
              // Ensure this method is called in response to a user action
              this.$store.state.dirHandle = await window.showDirectoryPicker();
              //this.getLocalFolders();
              // Optionally, you can handle the selected directory here
              console.log("Directory selected:", this.$store.state.dirHandle);
          } catch (error) {
              if (error.name === 'AbortError') {
                  console.log("User aborted the directory picker.");
              } else {
                  console.error("Error selecting directory:", error);
              }
          }
      },
    },
  
    watch: {
      termsAgreed(val) {
        if (!val) this.accountDetail.terms_agreed = 0;
      }
    },
  
    created: function() {
      //console.log(this.$route.params.id);
      this.loadingUserForm = true;
      this.axios
        .post("api", {
          method: "getAccount",
          id: this.$route.params.id
        })
        .then(response => {
          this.accountDetail = response.data.data;
          this.loadingUserForm = false;
  
          if (!this.scopeEdit) {
            this.accountDetail.scopekey = this.$store.state.account.scopekey;
            this.accountDetail.scopevalue = this.$store.state.account.scopevalue;
          }
  
          this.termsAgreed =
            Number(this.accountDetail.terms_agreed) >=
            Number(this.$store.state.requiredAgreementVersion);
  
          switch (this.$store.state.account.role) {
            case "coordinator":
              this.level = 2;
              break;
          }
        });
    },
  
    data: function () {
      return {
        fullEdit: !this.$route.params.profile,
        loadingUserForm: false,
        accountDetail: {},
        canEdit: true,
        passvalue: "",
        roles: [
          { text: "-- Vyberte -- ", value: null },
          "admin",
          "superuser",
          "lékař",
          "pacient"
        ],
        roles2: [
          { text: "-- Vyberte -- ", value: null },
          "examinator",
          "editor",
          "visitor"
        ],
        sortBy: "id",
        sortDesc: true,
        scopeEdit:
          this.$store.state.account.scopekey == null ||
          this.$store.state.account.scopekey == "",
        level: 1,
        termsAgreed: false,
        showProgress: false,
        processedRecordsCount: 0,
      };
    
    }
  };
  </script>
  
  <style></style>
  