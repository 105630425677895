<template>
  <div>
   
      <div class="row" style="background-color: #ededed;">
       <div class="col-12">
       <br>
      <img
        class="rounded mx-auto d-block"
        :src="logo"
        width="100"
      />
      <br />
      <h1 class="h3 mb-3 font-weight-normal">
         Ick aplikace
      </h1>
      <br>
      </div>
  </div> 
   
    <div class="row">
      <div class="col-12">
        <br>
        Správa dokumenů pacienta.
        <br>
        Informovaný souhlas.
      </div>
    
    </div>
  
  <!-- footer -->
    <div class="row">
      <div class="col-12">
         <br>
          Build {{ $store.state.appRelease }}
         <br>
         <br>
         <a href="https://www.icklinika.cz/">Všechna práva vyhrazena © 2024 IC Klinika Brno s.r.o.</a>
      </div>
  </div>

  </div>
</template>

<script>
import { setBaseURL, getIcUserByPid } from '../services/api';

export default {
  name: "AboutPage",
  components: {},
  data: function() {
    return {
      logo: require('@/assets/logo.png'),
      email: "",
      pass: "",
      loading: false,
      loginError: false,
      loginErrorMsg: "",
      roomId: "",
      user_accept: "0",
      terms_of_use_visible: false,
      accountDetail: {},
      asPatient: false,
      patientId: "",
    };
  },

  methods: {
    tryLogin: function() {

      this.tryLoginAsPatient();

    },

    tryLoginAsPatient() {
      if (this.loading) return;
      this.loginError = false;
      this.loading = true;
      this.axios
        .post("api", {
          method: "login",
          email: "pacient@ick.cz",
          pass: '1234'
        })
        .then(response => {
          //console.log("--- login ----");
          //console.log(response.data);
          if (response.data.status == "OK") {
            this.loginError = false;
            this.$store.commit("mutate", {
              property: "session",
              with: response.data.data.session
            });
            this.$store.commit("mutate", {
              property: "account",
              with: response.data.data.account
            });
            this.$store.commit("mutate", {
              property: "lists",
              with: response.data.data.lists
            });

            this.accountDetail = response.data.data.account;

            //console.log(this.accountDetail);

            var rightsLevel = 1;
            this.$store.commit("mutate", {
              property: "rights",
              with: rightsLevel
            });

          this.getUser();

          } else {
            this.loginError = true;
            this.loginErrorMsg = response.data.error.msg;
          }

          this.loading = false;
        })
        .catch(function(error) {
          //console.log(error);
          window.alert(error);
        });
    },

    async getUser() {
      setBaseURL(this.$store.state.config.serverUrl);
      //console.log("req1");
      const response = await getIcUserByPid(this.patientId); 
      //console.log(response);
      if ((response.data.status == "OK")) { 
        var user = response.data.data;
        if (user) {
          var userId = user.id;
          if (userId) {
            this.$router.push({ name: 'UserDetail', params: { id: userId } });
          } else {
            window.alert("Špatný údaj");
          }
        }
      }
      else
      {
        window.alert("Špatný údaj");
      }
    },

    restorePassword() {
      this.axios
        .post("api", {
          method: "restorePassword",
          data: this.email
        })
        .then(response => {
          console.log(response.data);
          window.alert(
            "Proběhla změna hesla. Nové heslo Vám bylo zasláno na " + this.email
          );
        });
    },

  

  //   checkTermsOfUse() {
  //     if (this.user_accept == "1") {
  //       //update account
  //       this.accountDetail.terms_agreed = this.$store.state.requiredAgreementVersion;
  //       this.loadingUserForm = true;
  //       this.axios
  //         .post("api", {
  //           method: "saveAccount",
  //           data: this.accountDetail
  //         })
  //         .then(response => {
  //           console.log("saveAccount");
  //           console.log(response.data);
  //         });
  //     }
  //   },
  },
};
</script>

<style scoped>
.form-signin {
  width: 100%;
  max-width: 400px;
  padding: 15px;
  margin: auto;
}

</style>
