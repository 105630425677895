<template>
  <div>
                
    <NavBar
      v-bind:items="[
        {
          text: 'Seznam',
          to: { name: 'Home' },
        },
        {
            text: userName, //'Detail pacienta - ' +
            to: { name: 'UserDetail' }
        },  
      ]"
    />
   
    <ModalDialog ref="modalTestList"  
    :message = dialogMessage
    :isVisible = showModal
    @update:response="handleDialogClose" />


    <div class="content-wrapper">
    
    <div v-if="user">
      <div class="container p-5">

      <b-tabs content-class="mt-3" v-model="activeTab">
          <b-tab title="Osobní údaje">
           
              <b-form class="col-9" @submit.stop.prevent>
                <!-- <b-form-group
                  id="input-group-id"
                  label="ID:"
                  label-for="input-id"
                  label-cols="4"
                  class="text-left"
                >
                  <b-form-input
                    id="input-id"
                    v-model="user.id"
                    disabled
                  />
                </b-form-group> -->

              

                <b-form-group
                  id="input-group-surname"
                  label="Příjmení:"
                  label-for="input-surname"
                  label-cols="4"
                  class="text-left"
                >
                <b-form-input
                    id="input-surname"
                    v-model="user.surname"
                    required
                    placeholder="Zadejte příjmení"
                    v-bind:disabled="!editEnabled"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-name"
                  label="Jméno:"
                  label-for="input-name"
                  label-cols="4"
                  class="text-left"
                >
                  <b-form-input
                    id="input-name"
                    v-model="user.name"
                    required
                    placeholder="Zadejte jméno"
                    v-bind:disabled="!editEnabled"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-title"
                  label="Titul:"
                  label-for="input-title"
                  label-cols="4"
                  class="text-left"
                >
                  <b-form-input
                    id="input-title"
                    v-model="user.title"
                    placeholder="Zadejte titul"
                    v-bind:disabled="!editEnabled"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-pid"
                  label="Rodné číslo:"
                  label-for="input-pid"
                  label-cols="4"
                  class="text-left"
                >
                  <b-form-input
                    id="input-pid"
                    v-model="user.pid"
                    required
                    placeholder="Zadejte r.č."
                    v-bind:disabled="!editEnabled"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-hi"
                  label="Zdravotní pojišťovna:"
                  label-for="input-hi"
                  label-cols="4"
                  class="text-left"
                >
                  <b-form-input
                    id="input-hi"
                    v-model="user.hi"
                    required
                    placeholder="Zadejte pojišťovnu"
                    v-bind:disabled="!editEnabled"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-address"
                  label="Adresa:"
                  label-for="input-address"
                  label-cols="4"
                  class="text-left"
                >
                  <b-form-input
                    id="input-address"
                    v-model="user.address"
                    required
                    placeholder="Zadejte adresu"
                    v-bind:disabled="!editEnabled"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-city"
                  label="Město/obec:"
                  label-for="input-city"
                  label-cols="4"
                  class="text-left"
                >
                  <b-form-input
                    id="input-city"
                    v-model="user.city"
                    required
                    placeholder="Zadejte město"
                    v-bind:disabled="!editEnabled"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-postcode"
                  label="PSČ:"
                  label-for="input-postcode"
                  label-cols="4"
                  class="text-left"
                >
                  <b-form-input
                    id="input-postcode"
                    v-model="user.postcode"
                    placeholder="Zadejte PSČ"
                    v-bind:disabled="!editEnabled"
                  ></b-form-input>
                </b-form-group>


                <b-form-group
                  id="input-group-phone"
                  label="telefon:"
                  label-for="input-phone"
                  label-cols="4"
                  class="text-left"
                >
                  <b-form-input
                    id="input-phone"
                    v-model="user.phone"
                    required
                    placeholder="Zadejte telefon"
                    v-bind:disabled="!editEnabled"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-email"
                  label="email:"
                  label-for="input-email"
                  label-cols="4"
                  class="text-left"
                >
                  <b-form-input
                    id="input-email"
                    v-model="user.email"
                    required
                    placeholder="Zadejte email"
                    v-bind:disabled="!editEnabled"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-passinfo"
                  label="Povolení:"
                  label-for="input-passinfo"
                  label-cols="4"
                  class="text-left"
                >

                <b-form-checkbox
                  id="input-passinfo"
                  v-model="user.passinfo"
                  :disabled="!editEnabled"
                  value="1"
                  unchecked-value="0"
                >
                  Poskytovat informace
                </b-form-checkbox>
               
                </b-form-group>

                <div v-if="user.passinfo == 1">
                <b-form-group
                  id="input-group-infperson1"
                  label="1.informovaná osoba:"
                  label-for="input-infperson1"
                  label-cols="4"
                  class="text-left"
                >
                  <b-form-input
                    id="input-infperson1"
                    v-model="user.infperson1"
                    placeholder="Zadejte osobní údaje"
                    v-bind:disabled="!editEnabled"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-infperson2"
                  label="2.informovaná osoba:"
                  label-for="input-infperson2"
                  label-cols="4"
                  class="text-left"
                >
                  <b-form-input
                    id="input-infperson2"
                    v-model="user.infperson2"
                    placeholder="Zadejte osobní údaje"
                    v-bind:disabled="!editEnabled"
                  ></b-form-input>
                </b-form-group>
                </div>

                <b-form-group
                  id="input-group-approveDate"
                  label="Datum:"
                  label-for="input-approveDate"
                  label-cols="4"
                  class="text-left"
                >
                  <b-form-input
                    id="input-approveDate"
                    type="date"
                    v-model="user.approvedate"
                    required
                    placeholder="Vyberte datum"
                    v-bind:disabled="!editEnabled"
                  ></b-form-input>

                </b-form-group>

                <div class="d-flex justify-content-start">
                <b-button
                  type="submit"
                  variant="primary"
                  v-show="editEnabled"
                  @click="saveUser"
                  ><i class="bi bi-save"></i> Uložit</b-button
                >
                &nbsp;
                <b-button
                  v-if="!newUser"
                  type="submit"
                  variant="danger"
                  v-show="editEnabled"
                  @click="deleteUser"
                  ><i class="bi bi-trash"></i> Smazat</b-button
                >
                &nbsp;
                <b-button
                  v-if="!newUser"
                  type="submit"
                  variant="success"
                  v-show="editEnabled"
                  @click="addUser"
                  ><i class="bi bi-plus"></i> Nový</b-button
                >
              
                </div>
              </b-form>
           
          </b-tab>
          <b-tab :title="`Dokumenty(${backendDocumentsCount})`">
          <div class="button-container d-flex justify-content-end mt-3">
            <b-button
              variant="success"
              @click="createNewDoc"
              class="float-right m-2"
              ><i class="bi bi-check"></i> Nový souhlas</b-button
            >
            
            <b-button
              variant="success"
              @click="addDoc(false)"
              class="float-right m-2"
              >
              <i class="bi bi-plus"></i> Vložit nový dokument
            </b-button>
            
            <b-button
              variant="success"
              @click="sendMail"
              class="float-right m-2"
              ><i class="bi bi-envelope"></i> Poslat mail</b-button
            >
            <input type="file" ref="fileInput" style="display: none;" @change="handleFileUpload"/>
          </div>
        
          <b-table
            :items="documents"
            :fields="fields"
            sort-icon-left
            responsive="sm"
            @row-clicked="rowClicked"
            class="text-left"  
          >
          <template v-slot:cell(delete)="data">
            <i class="bi bi-trash" @click.stop="deleteUserDocument(data.item)" style="cursor: pointer; color: red;"></i>
          </template>
          
          </b-table>

          </b-tab>
        
        <!-- local data -->
        <b-tab :title="`Data Teplotky(${dataDocumentsCount})`">

          <div v-if="$store.state.dirHandle">

          <div class="button-container d-flex justify-content-end mt-3">
                       
            <b-button
              variant="success"
              @click="addDoc(true)"
              class="float-right m-2"
              >
              <i class="bi bi-plus"></i> Vložit nový dokument
            </b-button>

            <input type="file" ref="fileInput" style="display: none;" @change="handleFileUpload"/>
          </div>

          <b-table
            :items="dataDocuments"
            :fields="fields"
            sort-icon-left
            responsive="sm"
            @row-clicked="rowClicked"
            class="text-left"  
          >
          <template v-slot:cell(delete)="data">
            <i class="bi bi-trash" @click.stop="deleteUserDocument(data.item)" style="cursor: pointer; color: red;"></i>
          </template>

         </b-table>

        </div>

         <div v-if="!$store.state.dirHandle">
          
            <!-- <b-button
              variant="success"
              @click="loadLocalFolders"
              class="float-right m-2"
              >Zvolit místní složku</b-button
            > -->
            Data nejsou načtena. Složku obsahující data pacientů vyberte tlačítkem <b>Vybrat složku data</b> na hlavní stránce.
        </div> 

        </b-tab>

        </b-tabs>
    
      </div>

           <!-- title="Toast Title" 
             auto-hide
             auto-hide-delay="500" 
              v-model="toastVisible"
            -->
      <div v-if="toastVisible">
        <b-toast v-model="toastVisible" variant="primary"
        > {{toastText}}
        </b-toast>
      </div>  
    
    </div>
    <div v-else>
      <p>Loading...</p>
    </div>
   
</div>
 
</div>
</template>


<script>
import { getIcUserById, saveIcUser, addIcUser, deleteIcUserById, getDocuments, saveIcFile, deleteIcFile, sendIcMail } from '../services/api'; // Adjust import based on your file structure
import { BFormInput, BTable } from 'bootstrap-vue-3'; 
import NavBar from "../components/navbar.vue";

import 'bootstrap-icons/font/bootstrap-icons.css';

import ModalDialog from "../components/modalDialog.vue";

export default {

  name: 'UserDetail',
  components: {
    BFormInput, 
    BTable,
    NavBar,
    ModalDialog,
  },
  data() {
    return {
      showModal: false,
      dialogMessage: "",
      user: null,
      newUser: false,
      editEnabled: true,
      documents: null,
      dataDocuments: [{}],
      fields: [
        // { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Název', sortable: true },
        { key: 'type', label: 'Typ', sortable: true },
        // { key: 'path', label: 'path', sortable: true },
        // { key: 'access', label: 'Přístup', sortable: true },
        { key: 'delete', label: 'Smazat', sortable: false }
       
      ],
      modalResponse: "",
      dvalue: "",
      userName: "",
      isDataSelect: false,
      userFolder: "",
      userFolderHandle: null,
      dataDocumentsCount: 0,
      backendDocumentsCount: 0,
      toastVisible: false,
      toastText: "",
      activeTab: 0,
    };
  },
  methods: {

    async getDataFolder() {
      this.dataDocuments.length = 0;
      if (this.$store.state.dirHandle) {  
        // Iterate over the contents of the directory
        for await (const entry of this.$store.state.dirHandle.values()) {
            if (entry.kind === 'directory') {
              if (entry.name == this.userFolder) {
                this.userFolderHandle = entry;
                // enumerate files
                for await (const fileEntry of entry.values()) {
                      if (fileEntry.kind === 'file') {
                        const file = await fileEntry.getFile();
                        const itemPath = "local/" + file.name;
                        console.log(itemPath);
                        this.dataDocuments.push({ 
                          name: this.removeExtension(file.name), 
                          path: itemPath, 
                          type: this.getFileType(file.name) 
                        });
                      }
                }
              }
            }
        }
        this.dataDocumentsCount = this.dataDocuments.length;
      }
    },

    handleDialogClose(newVal) {
      console.log(newVal)
      this.showModal = false;
    },

    async loadUser() {
      try {

        if (this.$route.params.id == -1) {

          //console.log("new user");
          
          this.userName = "nový pacient";

          this.user = {"name": ""};
          
          this.user.id = "";
          this.user.name = "";
          this.user.surname = "";
          this.user.title = "";
          this.user.pid = "";
          this.user.address = "";
          this.user.city = "";
          this.user.hi = "";
          this.user.phone = "";
          this.user.email = "";
          this.user.passinfo = "0";
          this.user.infperson1 = "";
          this.user.infperson2 = "";
          this.user.approveDate = null;
          this.user.passinfo = true;
          this.user.postcode = "";
          this.user.approvedate = "";
          this.user.access = null;
          this.user.approve_date = null;

          this.newUser = true;

        }
        else
        {
          const response = await getIcUserById(this.$route.params.id); 
          //console.log(response);
          this.user = response.data.data;  
          this.userName = this.user.surname +" "+ this.user.name; 
          this.userFolder = this.user.surname + "_" + this.user.pid;
          await this.getDocuments(this.user.id);

          if (this.$store.state.rights == 1) {
            this.createNewDoc();
          }

          this.activeTab = this.$store.state.config.selectedDocumentTab;
        }

      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    },

    async saveUser() {
     
      try {
        this.toastText = "Údaje pacienta byly uloženy";
        this.toastVisible = true;
        const allFieldsFilled = () => {
          return this.user.name && this.user.pid && this.user.address &&
             this.user.hi && this.user.phone && this.user.email;
         };

        if (this.newUser) {
          if (allFieldsFilled()) {
            console.log("add new...");
            console.log(this.user);
            const response = await addIcUser(this.user);
            console.log(response);
            
          } else {
          //  this.dialogMessage = "Chybí povinné údaje";
          //  this.showModal = true;
          }
          
        } else {
          const response = await saveIcUser(this.user); 
          console.log(response);
        }

        setTimeout(() => {
          this.toastVisible = false; 
        }, 1000);
        
       // this.user = response.data.data;  
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
      
    },


    addUser() {
      this.user.id = "";
      this.user.name = "";
      this.user.pid = "";
      this.user.address = "";
      this.user.hi = "";
      this.user.phone = "";
      this.user.email = "";
      this.user.passinfo = true;
      this.user.infperson1 = "";
      this.user.infperson2 = "";
      this.user.approveDate = null;

      this.newUser = true;
    },
    
    async deleteUser() {

      if ((this.backendDocumentsCount > 0) || (this.dataDocumentsCount > 0)) {
        this.dialogMessage = "Pacient má uložené dokumenty. Nelze smazat.";
        this.showModal = true;
        return;
      }

      const userResponse = confirm("Chcete smazat pacienta "+ this.user.surname +"?");
      if (userResponse) {
        try {
        const response = await deleteIcUserById(this.user.id);  
        console.log(response);
      // if (response)
        this.$router.push('/home');
       
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
      } else {
          // User clicked "Cancel"
      }
    },

    // backend doc
    async getDocuments() {
    try {
      const response = await getDocuments(this.user.id);
      this.documents = response.data.data.map(doc => {
        return {
          ...doc,
          type: this.getFileType(doc.path) // Assuming 'path' is where the filename is stored
        };
      });
      this.backendDocumentsCount = this.documents.length;

      this.getDataFolder();
      
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  },

    getFileType(filePath) {
    const extension = filePath.split('.').pop().toLowerCase();
    if (['mp4'].includes(extension)) {
      return 'Video';  // General label for video files
    } else if (['pdf'].includes(extension)) {
      return 'PDF';  // General label for PDF files
    } else if (['jpg'].includes(extension)) {
      return 'Obrázek';  // General label for image files
    } else if (['jfif'].includes(extension)) {
      return 'Obrázek';  // General label for image files
    }
    return 'Dokument';  // Handle unsupported file types
  },

    createNewDoc() {
      this.$router.push({ name: 'AgreementPage', params:  { patient: JSON.stringify(this.user) } });
    },
    rowClicked(item) {
      
      this.$store.state.config.selectedDocumentTab = this.activeTab;

      this.$store.state.userFolderHandle = this.userFolderHandle;
      this.$router.push({ name: 'DocumentView', params: { 
        path: item.path, 
        name: item.name, 
        userid: this.user.id,
        username: this.user.name + " " + this.user.surname,
        userpid: this.user.pid,
       } });
    },

    addDoc(isData) {
      this.isDataSelect = isData;
      this.$refs.fileInput.click();
    },

    // addDoc handler
    async handleFileUpload(event) {
    
    const file = event.target.files[0];
    if (file) { 
      if (this.isDataSelect){
        if (this.$store.state.dirHandle) {  
          console.log("Adding file on the DATA storage");
          try {
            const newFileHandle = await this.userFolderHandle.getFileHandle(file.name, { create: true });

            // Create a writable stream
            const writableStream = await newFileHandle.createWritable();

            // Write the file data to the stream
            await writableStream.write(file);

            // Close the stream
            await writableStream.close();

            console.log("File added to DATA: " + file.name);
          } catch (error) {
            console.error("Error adding file to DATA:", error);
          }
        }
        this.getDataFolder();
      } else {
        console.log("Adding file on the App backend");
        const response = await saveIcFile(this.user.id, this.removeExtension(file.name), file.name, "soubor");
        console.log(response);

        // upload file
        let formData = new FormData();
        formData.append('file', file);
        
        // add pid param
        formData.append('pid', this.user.pid);
      
        this.axios.post('https://ickapp.cz/backend/server/uploadfile.php', formData,
        {
          headers: {
          'Content-Type': 'multipart/form-data'
          }
        }
        ).then(function(data){
          console.log(data.data);
        })
        .catch(function(){
          console.log('FAILURE!!');
        });

        // update list
        this.getDocuments();
    }
  }

  else {
    alert("Please select a valid file.");
  }
  },

  async deleteUserDocument(item) {
    const userResponse = confirm("Chcete smazat dokument "+ item.name +"?");
      if (userResponse) {
      
        if ((item.path.includes("local/")) && (this.userFolderHandle)) {
          for await (const fileEntry of this.userFolderHandle.values()) {
            if (fileEntry.kind === 'file') {
              const file = await fileEntry.getFile();
              const itemPath = "local/" + file.name;
              if (itemPath == item.path) {
                await fileEntry.remove(); 
              }
            }
          }
          this.getDataFolder();

        } else {
          await deleteIcFile(this.user.pid, item.id);
          this.getDocuments();
        }
        
      }
  },

    removeExtension(filename) {
      const lastDotIndex = filename.lastIndexOf('.');
      if (lastDotIndex === -1 || lastDotIndex === 0) return filename;
      return filename.substring(0, lastDotIndex);
    },

    async sendMail() {
      const response = await sendIcMail(this.user.id);
      console.log(response);
      if (response.data.status == "OK") {
        window.alert("Mail s odkazem na formulář byl odeslán.");
      }
      else
      {
        window.alert("Mail s odkazem na formulář se nepodařilo odeslat.");
      }
    },
    
  },
  created() {
    this.loadUser(); 
  },
  
};
</script>

<style>

</style>
