<template>
  <div>
    
    <div ref="pdfContent">
    
    <div class="content-wrapper">

    <div class="page" id="page-1">  
    
    <table class="full-width-table">
      <tr>
        <td>IC Klinika Brno s.r.o 
          <br>
          Bratří Čapků 14
          <br>
          602 00 Brno
        </td>
        <td><img :src="logo" alt="Logo" class="navbar-image"></td>
        <td>
          Provozovna:
          <br>
          IC Klinika Brno
          <br>
          Bulharská 29, 612 00 Brno
        </td>
      </tr>
    </table> 

    <table class="full-width-table">
      <tr>
        <td>
          <h1>INFORMOVANÝ SOUHLAS S POSKYTNUTÍM INFORMACÍ O ZDRAVOTNÍM STAVU</h1>
        </td>
      </tr>
    </table>  

    <table class="full-width-table-framed">
      <tr>
        <td class="left-align">Jméno, příjmení a titul pacienta: 
          <br>
          <input type="text" v-model="fullName" 
          :class="{'input-border': !printView, 'input-no-border': printView}" 
          placeholder="Jméno, příjmení a titul" />
        </td>
        <td class="left-align">Rodné číslo/číslo pojištěnce (cizinci):
          <br>
          <input type="text" v-model="patient.pid" 
          :class="{'input-border': !printView, 'input-no-border': printView}" 
          placeholder="Rodné číslo/číslo pojištěnce" />
        </td>
      </tr>
      <tr>
        <td class="left-align">Bydliště-město, ulice, PSČ: 
          <br>
          <input type="text" v-model="patient.address"
           :class="{'input-border': !printView, 'input-no-border': printView}" 
            placeholder="Bydliště-město, ulice, PSČ" />
        </td>
        <td class="left-align">Zdravotní pojišťovna:
          <br>
          <input type="text" v-model="patient.hi"
          :class="{'input-border': !printView, 'input-no-border': printView}" 
            placeholder="Zdravotní pojišťovna" />
        </td>
      </tr>
      <tr>
        <td class="left-align">Telefon: 
          <br>
          <input type="text" v-model="patient.phone"
          :class="{'input-border': !printView, 'input-no-border': printView}" 
           placeholder="Telefon" />
        </td>
        <td class="left-align">Email:
          <br>
          <input type="text" v-model="patient.email"
          :class="{'input-border': !printView, 'input-no-border': printView}" 
           placeholder="Email" />
        </td>
      </tr>
      <tr>
        <td colspan="2" class="left-align">Jméno zákoného zástupce(pouze u dětí a nesvéprávné osoby): 
          <br>
          <input type="text" 
          :class="{'input-border': !printView, 'input-no-border': printView}" 
          placeholder="zákonný zástupce" />
        </td>
       
      </tr>
    </table> 

    <br>
    <p class="left-align">
    Prohlašuji, že souhlasím s využitím a zpracováním mého jména, příjmení a titulu, rodného čísla, čísla pojištěnce,
    bydliště, kódu zdravotní pojišťovny, e-mailové adresy a telefonního kontaktu v souladu s příslušnými
    právními předpisy EU.
    </p>
    <p class="left-align">
    Osoby, kterým mohou být poskytovány informace o mém zdravotním stavu
    <br>
    Prohlašuji, že souhlasím s poskytováním informací o mém zdravotním stavu v plném rozsahu (včetně práva nahlížet do zdravotnické dokumentace vedené o mé osobě a práva na pořízení výpisů, opisů nebo kopií dokumentů shromážděných v mé zdravotnické dokumentaci) těmto osobám:
    </p>

   
    <div class="left-align">
    <label class="checkbox-label">
      ANO
      <input type="checkbox" v-model="patient.passinfo" true-value="1" false-value="0"/>
    </label>
    
    <label class="checkbox-label">
      NE
      <input type="checkbox" :checked="patient.passinfo == 0"  @input="togglePassInfo" />
    </label>
    </div>


    <table v-if="patient.passinfo == 1" class="full-width-table-framed">
      <tr>
        <td class="left-align">Osoba č.1: 
          <br>
          Jméno a příjmení:
          <input type="text" v-model="patient.infperson1" 
          :class="{'input-border': !printView, 'input-no-border': printView}" 
           placeholder="Jméno, příjmení" />
          Vztah:
          <input type="text" 
          :class="{'input-border': !printView, 'input-no-border': printView}" 
           placeholder="vztah" />
          Kontakt, telefon:
          <input type="text" 
          :class="{'input-border': !printView, 'input-no-border': printView}" 
           placeholder="kontakt" />
        </td>
        <td class="left-align">Osoba č.2:
          <br>
          Jméno a příjmení:
          <input type="text" v-model="patient.infperson2" 
          :class="{'input-border': !printView, 'input-no-border': printView}" 
           placeholder="Jméno, příjmení" />
          Vztah:
          <input type="text" 
          :class="{'input-border': !printView, 'input-no-border': printView}" 
          placeholder="vztah" />
          Kontakt, telefon:
          <input type="text" 
          :class="{'input-border': !printView, 'input-no-border': printView}" 
           placeholder="kontakt" />
        </td>
      </tr>
    </table>  

  <!-- end of page 1   -->
  </div>  
    
    <div class="page" id="page-2">

    <br>
    <p class="left-align">
    Forma podávání informací o Vás
    <br>
    Souhlasím s předáváním informací souvisejících s mým zdravotním stavem prostřednictvím emailu, pošty ä telefonicky pomocí výše uvedených kontaktních údajů s uvedením hesla pro komunikaci.
    </p>

    
    <p class="left-align">
    HESLO: **** poslední 4 číslice čísla pojištěnce
    </p>

    
    <p class="left-align">
    ICK tímto výslovně pacienta upozorňuje, že takto postupuje s jeho souhlasem a nenese žádnou odpovědnost za
    případné chybné, neúplné či jinak nežádoucí doručení či nakládání s těmito informacemi, jelikož ICK nemůže tyto
    způsoby předání informací zcela zabezpečit proti úniku informací neoprávněné osobě. Současně pacient bere na
    vědomí, že uvedené nemá vliv na možnost uplatnění jeho práv, které pro něj vyplývají z předpisů GDPR.
    </p>

    <br>
    
    <table class="full-width-table-sign">
      <tr>
        <td>
          <input type="date" v-model="patient.approvedate" class="no-border" placeholder="datum" />
        </td>
        <!-- patient signature -->
        <td @click="showPatientSignatureDialog" style="cursor: pointer;"> 
          <div v-if="imgPatientSrc">
            <img :src="imgPatientSrc" alt="Podpis pacienta" class="signature-image" />
          </div>
        </td>
        <!-- staff signature -->
        <td @click="showStaffSignatureDialog" style="cursor: pointer;"> 
          <div v-if="imgStaffSrc">
            <img :src="imgStaffSrc" alt="Podpis zdravotníka" class="signature-image" />
          </div>
        </td>
      </tr> 
    </table> 
    <table class="full-width-table-sign2">
      <tr>
        <td>datum
        </td>
        <td>podpis pacienta
        </td>
        <td>podpis osoby, která informace získala 
        </td>
      </tr>
    </table> 


    <table class="footer-table">
      <tr>
        <td>
          &#9993; info@icklinika.cz
          <br>
          &#9742; +420 541 420 770-1
        </td>
        <td>
          &#127758; https://www.icklinika.cz
          <br>
          IČO: 268 85 361
        </td>
        <td>
          <b-button
          v-if="!printView"        
            variant="success"
            @click="saveForm"
            >{{buttonTitle}}</b-button
            >
          <!-- &nbsp;
          <b-button
          v-if="!printView"        
            variant="success"
            @click="showSignatureDialog"
            >Podepsat</b-button
            > -->
        </td>
      </tr> 
    </table> 

    <b-modal v-model="signatureDialog" 
     :title = "collectPatientSignature ? 'Podpis pacienta' : 'Podpis zdravotníka'"
     hide-footer>
        <SignPad ref="signPadPatient" @saveCanvasData="handleCanvasData"></SignPad>
    </b-modal>
 
    </div>
  </div>
</div>
</div>
</template>
  
  <script>
  import { saveIcUser, saveIcFile } from '../services/api'; // Adjust import based on your file structure
  import html2pdf from 'html2pdf.js';
  import SignPad from "../components/signature.vue";

  export default {
    name: 'AgreementPage',
    components: {
      SignPad,
  },
    data() {
    return {
      user: null,
      patient: null,
      userName: "",
      logo: require('@/assets/logo.png'),
      printView: false,
      imgPatientSrc: null,
      imgStaffSrc: null,
      signatureDialog: false,
      collectPatientSignature: true,
      buttonTitle: "Uložit",
      isPatient: false,
    };
  },
  computed: {
  fullName: {
    get() {
      let fullName = `${this.patient.name} ${this.patient.surname}`.trim();

      // Add a comma and title if the title is not empty or null
      if (this.patient.title) {
        fullName += fullName ? `, ${this.patient.title}` : this.patient.title;
      }
      return fullName;
    },
    set(value) {
      const names = value.split(' ');
      this.patient.name = names.slice(0, -1).join(' '); // Everything except the last part
      this.patient.surname = names[names.length - 1]; // Last part as surname
    }
   }
  },
  methods: {

    showPatientSignatureDialog() {
      this.collectPatientSignature = true;
      this.$refs.signPadPatient.clearPatientCanvas();
      this.signatureDialog = true;
    },
    showStaffSignatureDialog() {
      this.collectPatientSignature = false;
      this.$refs.signPadPatient.clearPatientCanvas();
      this.signatureDialog = true;
    },

    handleCanvasData(imgData) {
      if (this.collectPatientSignature ) {
        this.imgPatientSrc = imgData;
      } 
      else
      {
        this.imgStaffSrc = imgData;
      }
      this.signatureDialog = false;
    },
   
    togglePassInfo(event) {
      // Set passinfo to the opposite value of the second checkbox
      this.patient.passinfo = !event.target.checked; // Invert the value
    },
    async saveForm() {
      this.printView = true;
      console.log(this.user);
      const response = await saveIcUser(this.patient); 
      console.log(response);

      var newName = "souhlas-" + this.patient.approvedate + ".pdf";

      const responseSaveFile = await saveIcFile(this.patient.id, this.removeExtension(newName), newName, "soubor");
      console.log(responseSaveFile);

      await this.generatePdf(newName);
      if (this.isPatient) {
        window.alert("Formulář byl odeslán.");
        this.$store.dispatch("logout");
        this.$router.replace({ name: 'AboutPage' });
      }
      else
      {
        this.$router.go(-1);
      }
    },
    async generatePdf(fileName) {
      const element = this.$refs.pdfContent;

      const pdfOptions = {
        margin: [0, 0, 0, 0],
        filename: fileName,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 1 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
      };

      // Generate PDF and get as a Blob
      html2pdf()
        .from(element)
        .set(pdfOptions)
        .outputPdf('blob')
        .then(blob => {
          this.uploadPdf(fileName, blob);
        });
    },
   
    async uploadPdf(fileName, blob) {

      const formData = new FormData();
      formData.append('file', blob, fileName);
      formData.append('pid', this.patient.pid);

      this.axios.post('https://ickapp.cz/backend/server/uploadfile.php', formData,
      {
        headers: {
        'Content-Type': 'multipart/form-data'
        }
      }
      ).then(function(data){
        console.log(data.data);
      })
      .catch(function(){
        console.log('FAILURE!!');
      });
    
    },

    removeExtension(filename) {
      const lastDotIndex = filename.lastIndexOf('.');
      if (lastDotIndex === -1 || lastDotIndex === 0) return filename;
      return filename.substring(0, lastDotIndex);
    },
    
  },

  created() {
    if (this.$route.params.patient) {
    this.patient = JSON.parse(this.$route.params.patient);
    //console.log(this.patient.passinfo);
    console.log(this.$store.state.rights);
    if (this.$store.state.rights == 1) {
      this.isPatient = true;
      this.buttonTitle = "Odeslat";
    }

  }

  },
  
};
 
 
  </script>

<style scoped>

.signature-image {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
.full-width-table-sign td {
  padding: 5px;
}


.navbar-image {
  height: 40px; /* Set the desired height of the image */
  margin-right: 15px; /* Adds space between the image and the nav items */
}


.content-wrapper {
  width: 90%;
  margin: 0 auto; /* Centers the div */
}

.full-width-table {
  width: 100%; /* Make the table full width */
  border-collapse: collapse; /* Optional: To remove spaces between cells */
}

.full-width-table td {
  padding: 8px; /* Optional: Adds some padding inside cells */
}

.full-width-table-framed {
  width: 100%; /* Make the table full width */
  border-collapse: collapse; /* Optional: To remove spaces between cells */
}

.full-width-table-framed td {
  border: 1px solid #848484;  
  padding: 8px; /* Optional: Adds some padding inside cells */
}

.left-align {
  text-align: left;
}

.no-border {
  border: none;
  outline: none; /* Optional: Remove the outline on focus */
  width: 100%; /* Optional: Make the input take full width */
  padding: 5px;   /* Optional: Adjust padding */
}

.checkbox-label {
  margin-bottom: 10px; /* Adjust the value as needed for spacing */
  margin-right: 20px;
}

.full-width-table-sign {
    width: 100%; /* Ensure the table takes full width */
    table-layout: fixed; /* Use fixed layout */
    border: 1px solid black; /* Optional: border for visual clarity */
  }
.full-width-table-sign td {
    height: 120px;  
    width: 33.33%; /* Equal width for each column */
    border: 1px solid #ddd;  
    text-align: center; 
    vertical-align: middle; 
    padding: 10px; 
  }
 .full-width-table-sign2 {
    width: 100%; /* Ensure the table takes full width */
    table-layout: fixed; /* Use fixed layout */
 }
.full-width-table-sign2 td {
  width: 33.33%; /* Equal width for each column */
  text-align: center;
  padding: 10px; /* Optional: add some padding */
 }

 .footer-table {
    width: 100%; /* Ensure the table takes full width */
    table-layout: fixed; /* Use fixed layout */
 }

 .page {
  page-break-after: always; /* Ensures each div is treated as a new page */
  padding: 20px; /* Add padding as needed */
}

h1 {
  font-size: 26px; 
}
p {
  font-size: 14px;
}
.input-border {
  border: 1px solid lightgrey; /* Light grey border */
  border-radius: 4px; /* Add slight rounding to the corners */
  padding: 10px; /* Add padding for better text positioning */
  width: 100%; /* Make it responsive */
  box-sizing: border-box; /* Ensure padding and border are included in width */
  transition: border-color 0.2s; /* Smooth transition for focus */
}

.input-no-border {
  border: 1px solid white; /* Light grey border */
  border-radius: 4px; /* Add slight rounding to the corners */
  padding: 10px; /* Add padding for better text positioning */
  width: 100%; /* Make it responsive */
  box-sizing: border-box; /* Ensure padding and border are included in width */
  transition: border-color 0.2s; /* Smooth transition for focus */
}

.input-border:focus {
  border-color: #007BFF; /* Change border color on focus (optional) */
  outline: none; /* Remove default outline on focus */
}

</style>