<template>
    <div>
      <div class="signature-content-canvas">
         <canvas id="patient-signature" ref="patientCanvas" width="400" height="200" />
      </div>
      <br>
      <b-button variant="danger" @click="clearPatientCanvas()"><i class="bi bi-trash"></i> Smazat</b-button>
      &nbsp;
      <b-button variant="primary" @click="savePatientCanvas()"><i class="bi bi-save"></i> Uložit</b-button>
    </div>
</template>
  
<script>
  export default {
    name: "SignPad",
    data() {
      return {
        canvas: null,
        signaturePanelInit: false,
        flag: false,
        prevX: 0,
        currX: 0,
        prevY: 0,
        currY: 0,
        dot_flag: false,
        canvasBg: 'white',
        penColor: 'blue',
        penWidth: 1,
     };
    },
  
    methods: {
  
      clearPatientCanvas() {
        const canvas = document.getElementById('patient-signature')
        this.clearDrawing(canvas, this.canvas)
      },

      savePatientCanvas() {
        const canvas = document.getElementById('patient-signature');
        if (canvas) {
            const imgData = canvas.toDataURL('image/png');
            this.$emit('saveCanvasData', imgData);
        }
      },
  
      async initSignaturePanels() {
        if (!this.signaturePanelInit) {
            this.initCanvasEvents("patient-signature", this.canvas);
            this.initCanvasResize();
          this.signaturePanelInit = true
        }
      },

      clearDrawing(cn, ctx) {
    if (cn) {
      if (ctx) {
        ctx.fillStyle = this.canvasBg
        ctx.fillRect(0, 0, cn.width, cn.height)
      }
    }
  },

  drawLine(ctx, x1, x2, y1, y2) {
    ctx.beginPath()
    ctx.moveTo(x1, y1)
    ctx.strokeStyle = this.penColor
    ctx.lineWidth = this.penWidth
    ctx.lineTo(x2, y2)
    ctx.stroke()
  },


  resizeCanvases() {
    [].forEach.call(document.querySelectorAll('.signature-canvas'), (canvas) => {
      delete canvas.width
      delete canvas.height
  
      const container = canvas.parentNode
      const context = canvas.getContext('2d')
      const rect = container.getBoundingClientRect()
  
      canvas.width = rect.width
      canvas.height = rect.height
  
      // Clear canvas content
      context.clearRect(0, 0, canvas.width, canvas.height)
      context.fillStyle = this.canvasBg
      context.fillRect(0, 0, canvas.width, canvas.height)
    })
  },

  initCanvasResize() {
    // resizeCanvases()
  
    window.addEventListener('resize', () => {
      this.resizeCanvases()
    })
  },

  initCanvasEvents(canvasId, ctx) {
    const canvas = document.getElementById(canvasId)

    if (canvas) {
      // Prevent scrolling when touching the canvas
      document.body.addEventListener('touchstart', function(e) {
        if (e.target === canvas) {
          e.preventDefault()
        }
      }, { passive:false })
      document.body.addEventListener('touchmove', function(e) {
        if (e.target === canvas) {
          e.preventDefault()
        }
      }, { passive:false })
      document.body.addEventListener('touchend', function(e) {
        if (e.target === canvas) {
          e.preventDefault()
        }
      }, { passive:false })
  
      // handle touch input
      canvas.addEventListener('touchstart', (e) => {
        this.drawCanvas(canvas, ctx, 'down', e.touches[0].clientX, e.touches[0].clientY)
      }, false)
      canvas.addEventListener('touchmove', (e) => {
        this.drawCanvas(canvas, ctx, 'move', e.touches[0].clientX, e.touches[0].clientY)
      }, false)
      canvas.addEventListener('touchend', () => {
        this.drawCanvas(canvas, ctx, 'up', 0, 0)
      }, false)
  
      // handle mouse input
      canvas.addEventListener('mousedown', (e) => {
        this.drawCanvas(canvas, ctx, 'down', e.clientX, e.clientY)
      }, false)
      canvas.addEventListener('mousemove', (e) => {
        this.drawCanvas(canvas, ctx, 'move', e.clientX, e.clientY)
      }, false)
      canvas.addEventListener('mouseup', (e) => {
        this.drawCanvas(canvas, ctx, 'up', e.clientX, e.clientY)
      }, false)
      canvas.addEventListener('mouseout', (e) => {
        this.drawCanvas(canvas, ctx, 'out', e.clientX, e.clientY)
      }, false)
  
      this.clearDrawing(canvas, ctx)
    }
  },

  drawCanvas(canvas, ct, action, x, y) {
    const rect = canvas.getBoundingClientRect()
  
    switch (action) {
      case 'down':
      this.prevX = this.currX
      this.prevY = this.currY
      this.currX = (x - rect.left) / (rect.right - rect.left) * canvas.width
      this.currY = (y - rect.top) / (rect.bottom - rect.top) * canvas.height
  
      this.flag = true
      this.dot_flag = true
        if (this.dot_flag) {
          ct.beginPath()
          ct.fillStyle = this.penColor
          ct.fillRect(this.currX, this.currY, 2, 2)
          ct.closePath()
          this.dot_flag = false
        }
        break
      case 'up':
      case 'out':
      this.flag = false
        break
      case 'move':
        if (this.flag) {
            this.prevX = this.currX
            this.prevY = this.currY
            this.currX = (x - rect.left) / (rect.right - rect.left) * canvas.width
            this.currY = (y - rect.top) / (rect.bottom - rect.top) * canvas.height
          this.drawLine(ct, this.prevX, this.currX, this.prevY, this.currY)
        }
        break
    }
},


    },
  
    async mounted() {
      this.canvas = (this.$refs.patientCanvas).getContext('2d')
      await this.initSignaturePanels()
    },
};
  
  </script>
  
  <style scoped>
  canvas {
    width: 100%;
    height: 200px;
    cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"  width="40" height="48" viewport="0 0 100 100" style="fill:black;font-size:24px;"><text y="50%">✍️</text></svg>') 5 25,auto;
  }
  
  .signature-content-canvas {
    border-radius: 5px;
    padding: rem(5px);
    border: 3px solid rgb(154, 141, 141);
  }
  
  #canvas {
    display: none;
  }
        
  </style>
  